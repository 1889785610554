import React from 'react'
import {ReactComponent as WhatsappIcon} from "../../assets/icons/whatsapp.svg";
import ReactWhatsapp from 'react-whatsapp';
import { Col, Row } from 'react-bootstrap';

export const Whatsapp = () => {
    return (
        <div style={{position:"fixed",bottom:20,right:10,zIndex:5}}>

            <ReactWhatsapp number="+916282724667"
                style={
                    {
                        background: "none",
                        border: "none",
                    }
            }>
               
                  <WhatsappIcon style={{maxWidth:'40vw'}}/>
                  
                    </ReactWhatsapp>
        </div>
    )
}
