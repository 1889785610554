import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/firestore";

const config = {
    apiKey: "AIzaSyCV2n--GxkLJpwvL__73SSnVpaUMWZTgK4",
    authDomain: "e2mkerala.firebaseapp.com",
    projectId: "e2mkerala",
    storageBucket: "e2mkerala.appspot.com",
    messagingSenderId: "261381239807",
    appId: "1:261381239807:web:e1e072e22feac8662b2dca",
    measurementId: "G-8KCPX50NBF"
};
firebase.initializeApp(config);

export default firebase;
