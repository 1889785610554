import React from "react";

function Loading() {
  return (
    <div style={{ flex: 1, alignSelf: "center", margin: 100 }}>
      <center>
        <div class="spinner-grow text-warning" role="status"></div>{"  "}
        <div class="spinner-grow text-primary" role="status"></div>{"  "}
        <div class="spinner-grow text-success" role="status"></div>
      </center>
    </div>
  );
}

export default Loading;
