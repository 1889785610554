import {Col, Container, Row} from "react-bootstrap";
import {Fade} from "react-reveal";
import Image from "../../assets/lead.png";
import "./lead.css";
import Logo from "../../assets/safe logo.png";
import Logo2 from "../../assets/logo2.png";
import ISTLogo from "../../assets/ist.png";
import Year from "../../assets/2004.png";
import Arr1 from "../../assets/arrow1.png";
import Arr2 from "../../assets/arrow2.png";
import {BrowserView, MobileOnlyView, MobileView} from "react-device-detect";

const About = () => {
    return (
        <Container className="mt-3 p-5" id="about">
            <Fade up>
                <center>
                    <h1 className="m-3">About Us</h1>
                    <p>E2m enjoy every moment is founded by 3 ambitious friends (Adesh Sudheendralal,  Nibin Shajan  and  Janvin  Davis)  with 
                                                an  aim  of  adding their  unique methods  to  make  people engage in different aspects of  tourism which  include  various cultures, lifestyle, food habits, activities which are unique to a certain destination. This will ensure that people will  be a  part of different taste of life and have  an unforgettable experience. We are dedicated in finding out unique resorts,  spreading  awareness  about  them  and  making  it  more accessible for people.We also provide taxi services with experienced drivers and tour guides.  We  are  dedicated to develop Health  and Adventure  Tourism  with  various activities at different parts of the country.</p>
                </center>
            </Fade>
        </Container>
    );
};

export default About;
