import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import ClientImg from "../../assets/client.png";
import "./customer.css";
import {ReactComponent as Quote1} from "../../assets/quoteLeft.svg";
import {ReactComponent as Quote2} from "../../assets/quoteRight.svg";
import CSImg from "../../assets/customers/c1.png";
import {Zoom} from "react-reveal";
import firebase from '../../firebase';
import Slider from "react-slick";
import {BrowserView, MobileOnlyView, MobileView} from "react-device-detect";

const Customer = () => {

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0)

    useEffect(() => {
        fetchDatas();
    }, []);

    const fetchDatas = async () => {
        try {

            var datas = [];
            var snapshot = await firebase.firestore().collection("testimonials").get();
            snapshot.forEach((doc) => {
                datas.push(doc.data());

            });
            setItems([... datas]);

        } catch (err) {
            console.log(err);
        }

    };


    return (<Container id="reviews" fluid className="bg-gray p-5">
        <h1 className="text-center dark-color mb-2">Reviews</h1>
        <Container> 
            <Row> {
                items.length <= 3 ? <BrowserView>
                    <Slider dots={true}
                        slidesToShow={
                            items.length
                        }
                        autoplay={true}
                        autoplaySpeed={2500}> {
                        items.map((p) => (<Col md={3}
                            lg={3}
                            xs={12}>
                            <center>
                                <div className="cs-card">
                                  
                                    <p>  <b>"</b> {
                                        p.review
                                    }  <b>"</b></p>
                                    <Row>
                                        <Col lg={2}></Col>
                                        <Col lg={3}>
                                            <center>
                                                <img src={
                                                        p.url
                                                    }
                                                     />
                                            </center>
                                        </Col>
                                        <Col lg={5}>
                                            <center>
                                                <h6 className="text-center"> {
                                                    p.name
                                                }</h6>
                                                <p className="text-center"> {
                                                    p.designation
                                                }</p>
                                            </center>
                                        </Col>
                                        <Col lg={2}></Col>
                                    </Row>

                                </div>
                            </center>
                        </Col>))
                    } </Slider>
                    <br/>
                </BrowserView> : <BrowserView>
                    <Slider dots={true}
                        slidesToShow={3}
                        autoplay={true}
                        autoplaySpeed={2500}> {
                        items.map((p) => (<Col md={3}
                            lg={3}
                            xs={12}>
                            <center>
                                <div className="cs-card">
                                  
                                    <p>   <b>"</b>{
                                        p.review
                                    }  <b>"</b></p>
                                    <Row>
                                        <Col lg={2}></Col>
                                        <Col lg={3}>
                                            <center>
                                                <img src={
                                                        p.url
                                                    }
                                                     />
                                            </center>
                                        </Col>
                                        <Col lg={5}>
                                            <center>
                                                <h6 className="text-center"> {
                                                    p.name
                                                }</h6>
                                                <p className="text-center"> {
                                                    p.designation
                                                }</p>
                                            </center>
                                        </Col>
                                        <Col lg={2}></Col>
                                    </Row>

                                </div>
                            </center>
                        </Col>))
                    } </Slider>
                    <br/>
                </BrowserView>
            }
                <MobileOnlyView>
                    <Slider slidesToShow={1}
                        dots={true}
                        autoplay={true}
                        autoplaySpeed={2500}> {
                        items.map((p) => (<Col 
                            lg={4}
                            xs={12}>
                            <center>
                                <div className="cs-card">
                                   
                                    <p> <b>"</b> {
                                        p.review
                                    } <b>"</b></p>
                                    <Row>
                                        <Col lg={2}></Col>
                                        <Col lg={3}>
                                            <center>
                                                <img src={
                                                        p.url
                                                    }
                                                     />
                                            </center>
                                        </Col>
                                        <Col lg={5}>
                                            <center>
                                                <h6 className="text-center"> {
                                                    p.name
                                                }</h6>
                                                <p className="text-center"> {
                                                    p.designation
                                                }</p>
                                            </center>
                                        </Col>
                                        <Col lg={2}></Col>
                                    </Row>

                                </div>
                            </center>
                        </Col>))
                    } </Slider>
                </MobileOnlyView>
            </Row>

        </Container>
    </Container>);
};

export default Customer;
