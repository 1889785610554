import React from "react";

import Customer from "../../Components/Customer";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/Nav";
import Offer from "../../Components/offer";
import Sec1 from "../../Components/Sec1";
import About from "../../Components/About";
import Aim from "../../Components/Aim";
import Packages from "../../Components/Packages";
import Services from "../../Components/Services";

const Home = () => {
  return (
    <div>
      <NavBar />
      <Sec1 />
      <Aim/>
      <About />
      <Packages/>
      <Services/>
      <Customer />

      <Footer />
    </div>
  );
};

export default Home;
