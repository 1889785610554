import {Col, Container, Row} from "react-bootstrap";
import Image from "../../assets/mainimg.png";
import "./sec1.css";
import {Fade, Zoom} from "react-reveal";
import {ReactComponent as Map} from "../../assets/map.svg";
import {ReactComponent as IntroText} from "../../assets/introtext.svg";
import {BrowserView, MobileOnlyView} from "react-device-detect";

import {ReactComponent as UpQuote} from "../../assets/UpQuote.svg";
import {ReactComponent as DownQuote} from "../../assets/DownQUote.svg";

const Sec1 = () => {
    return (
        <Container id="home">
            <Row>
                <Col md={5}
                    lg={4}
                    xs={12}
                    style={
                        {
                            paddingLeft: 50,
                            paddingRight: 50
                        }
                }>
                    <Fade down>
                        {/* <IntroText className="w-100"/> */}
                        <UpQuote style={{maxWidth:'100%'}}/>
                            <h1 className="text-center p-3">Think Different
                                <br/>
                                And  <br/>Experience better</h1>
                        <DownQuote style={{maxWidth:'100%'}}/>
                    </Fade>
                </Col>
                <Col></Col>
                <Col lg={7}
                    md={7}
                    xs={12}>
                    <Fade right>
                        <center>
                            <BrowserView>
                                <Map className="w-100 mt-3"/>
                            </BrowserView>
                            <MobileOnlyView>
                                <Map className="w-100 mt-0"/>
                            </MobileOnlyView>
                        </center>
                    </Fade>
                </Col>
            </Row>
        </Container>
    );
};

export default Sec1;
