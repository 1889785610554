import React, {useRef, useState} from "react";
import {
    Col,
    Container,
    Form,
    Row,
    Spinner
} from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "./footer.css";
import Logo from "../../assets/safe logo.png";

import Logo2 from "../../assets/logo2.png";
import ISTLogo from "../../assets/ist.png";
import {ReactComponent as FB} from "../../assets/icons/facebook-round.svg";
import {ReactComponent as TW} from "../../assets/icons/twitter-round.svg";
import {ReactComponent as YT} from "../../assets/icons/linkedin-round.svg";
import {ReactComponent as IG} from "../../assets/icons/insta.svg";

import {ReactComponent as CSvg} from "../../assets/contact.svg";
import {ReactComponent as Tick} from "../../assets/icons/tick.svg";

import {ReactComponent as Home} from "../../assets/office.svg";
import {ReactComponent as Phone} from "../../assets/phone.svg";
import {ReactComponent as Mail} from "../../assets/mail.svg";

import {Fade, Zoom} from "react-reveal";

const Footer = () => {
    const form = useRef();
    const [load, setLoad] = useState(false);
    const [sent, setSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoad(true);
        const data = new FormData(e.target);
        const name = data.get("name");
        const email = data.get("email");
        const message = data.get("message");
        const phone = data.get("phone");

        console.log(name);

        if (name != "" && email != "" && message != "") {
            emailjs.sendForm("service_23x4bxp", "template_05bb1q8", form.current, "1vJbno93rBzcN3SHy").then((result) => {
                setSent(true);
                setLoad(false);
                document.getElementById("form").reset();
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        } else {
            setLoad(false);
            alert("Please fill out all details !");
        }
    };

    return (
        <div className="footer pt-5 p-1">
            <Container>
                <h1 className="white-color text-center">Get In Touch</h1>
                <h6 className="light-blue-color text-center mb-5 mt-3">
                    Planning your next vecation? Or searching  for trip ideas?

                </h6>

                <Fade up>
                    <div className="form-card" id="contact">
                        {
                        sent == false ? (
                            <Row>

                                <Col>
                                    <CSvg className="w-100"/>
                                </Col>
                                <Col lg={6}>
                                    <Form ref={form}
                                        onSubmit={sendEmail}
                                        id="form">

                                        <input type={"text"}
                                            className="form-control"
                                            placeholder="type your name.."
                                            name="name"/>
                                        <input type={"email"}
                                            className="form-control mt-2"
                                            placeholder="type your email.."
                                            name="email"/>
                                        <input type={"text"}
                                            className="form-control mt-2 mb-2"
                                            placeholder="type your phone.."
                                            name="phone"/>

                                        <textarea className="form-control mt-0" placeholder="type your message.." name="message"
                                            rows={5}/>

                                        <Row>
                                            <Col> {
                                                load == true ? (
                                                    <Spinner className="m-2" animation="border" variant="primary"/>
                                                ) : (
                                                    <button type="submit"
                                                        style={
                                                            {
                                                                backgroundColor: "#1B3250",
                                                                border: "none",
                                                                paddingRight: 20,
                                                                paddingLeft: 20,
                                                                padding: 10,
                                                                color: "#00A651",
                                                                borderRadius: 10,
                                                                marginBottom: 30,
                                                                color: "#fff",
                                                                marginTop: 15
                                                            }
                                                        }
                                                        className="w-100">
                                                        submit
                                                    </button>
                                                )
                                            } </Col>

                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                        ) : (
                            <Fade>
                                <div>

                                    <Row>
                                        <Col>
                                            <Zoom>
                                                <center><Tick/></center>
                                            </Zoom>
                                            <h3 className="m-5 blue-color text-center">
                                                Our Team will get back to you soon..
                                            </h3>
                                        </Col>
                                        <Col xs={12}>
                                            <CSvg className="w-100"/>
                                        </Col>
                                    </Row>
                                </div>
                            </Fade>
                        )
                    } </div>
                    <h6 className="light-blue-color text-center mb-5 mt-3">We love talking about travel
                                                                                                 with you. Drop us a message 
                                                                                                and one of our team members
                                                                                                 will be in touch with you</h6>
                </Fade>


                <Row className="mt-5">
                    <Col md={3}
                        lg={3}
                        xs={12}>

                        <img src={Logo2}
                            className="w-50"/>
                    </Col>
                    <Col md={3}
                        lg={3}
                        xs={12}>
                        <h6 className="white-color">Address</h6>
                        <div className="white-line w-75"/>
                        <Row className="mt-1">
                            <Col lg={1}>
                                <Home/>
                            </Col>
                            <Col lg={11}
                                xs={10}>
                                <h6 className="white-color">

                                    PK Building
                                    <br/>
                                    Thalikulam centre<br/>
                                    Thrissur, Kerala
                                    <br/>
                                    Pin - 680569


                                </h6>
                            </Col>
                        </Row>

                    </Col>
                    <Col lg={3}>
                        <h6 className="white-color">Contact</h6>
                        <div className="white-line w-75"/>
                        <Row className="mt-1">
                            <Col lg={1}
                                xs={2}>
                                <Mail/>
                            </Col>
                            <Col lg={11}
                                xs={10}>
                                <h6 className="white-color">

                                    e2mholidays@gmail.com
                                </h6>
                            </Col>
                        </Row>
                        <Row className="mt-1 mb-1">
                            <Col lg={1}
                                xs={2}>
                                <Phone/>
                            </Col>
                            <Col lg={11}
                                xs={10}>
                                <h6 className="white-color">
                                    +91 6282724667<br/>
                                    +91 7907198811<br/>
                                    +91 8943392024
                                </h6>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}
                        lg={3}
                        xs={12}>
                        <h6 className="white-color text-center">Social Media</h6>
                        <div className="white-line w-100"/>
                        <Row className="mt-4">
                            <Col>
                                <center>
                                    <a href="https://www.facebook.com/profile.php?id=100094626992896&mibextid=ZbWKwL">
                                        <FB/>
                                    </a>
                                </center>
                            </Col>
                            <Col>
                                <center>
                                    <a href="https://instagram.com/e2m_enjoy_every_moment?igshid=NGExMmI2YTkyZg==">
                                        <IG/>
                                    </a>
                                </center>
                            </Col>
                            <Col>
                                <center>
                                    <a href="https://twitter.com/E2m_2000?t=E94ZBiXElJgLce9EOIpbGA&s=09">
                                        <TW/>
                                    </a>
                                </center>
                            </Col>
                            <Col>
                                <center>
                                    <a href="https://youtube.com/@E2Menjoyeverymoment">
                                        <YT/>
                                    </a>
                                </center>
                            </Col>


                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Footer;
