import React from 'react'
import AimImg from '../../assets/Aim.png'
import {Container} from 'react-bootstrap'
import {BrowserView, MobileOnlyView} from 'react-device-detect'

const Aim = () => {
    return (
        <div>
            <Container>
                <MobileOnlyView>
                    <center>
                        <img src={AimImg}
                            className='w-100'/>
                    </center>
                </MobileOnlyView>
                <BrowserView>
                    <center>
                        <img src={AimImg}
                            className='w-75'/>
                    </center>
                </BrowserView>
            </Container>
        </div>
    )
}

export default Aim
