import React, {useEffect, useState, useRef, useMemo} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Check from "../../Components/Check";
import Loading from "../../Components/Loading";

import firebase from "../../firebase";
import "./arrivals.css";

const AddTestimonials = () => {
    const [items, setItems] = useState([]);
    const [image, setImage] = useState(null);
    const [transfer, setTransfer] = useState();
    const [loading, setLoading] = useState();
    const [name, setName] = useState();
    const [designation, setDesignation] = useState();
    const [review, setReview] = useState();

    const editor = useRef(null);
    const [content, setContent] = useState('');


    useEffect(() => {
        fetchArrivals();
    }, []);

    const fetchArrivals = async () => {
        try {
            var datas = [];
            var snapshot = await firebase.firestore().collection("testimonials").get();
            snapshot.forEach((doc) => {
                datas.push(doc.data());
            });
            setItems([... datas]);
        } catch (err) {
            console.log(err);
        }
    };

    const removeProduct = async (uid, url, cat) => {
        if (window.confirm("Delete the item?")) {
            try {
                setLoading(true);
                let imageRef = firebase.storage().refFromURL(url);
                let task2 = imageRef.delete();
                await task2;
                task2.then(function () {
                    firebase.firestore().collection("testimonials").doc(uid).delete().then((res) => {
                        alert("Post removed successfully !");
                        window.location.reload();
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }
    };

    const upload = async (cat) => {
        if (image != null) {
            let date = new Date();
            date = date.toString();
            let pname = image.name + date;

            setLoading(true);
            if (image == null) 
                return;
            

            const storageRef = firebase.storage().ref(`/clients/${pname}`);
            const task = storageRef.put(image);

            task.on("state_changed", (taskSnapshot) => {
                setTransfer(Math.round(taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
                console.log(transfer);
            });
            try {
                await task;
                task.then(function (snapshot) {
                    storageRef.getDownloadURL().then((url) => {
                        const docRef = firebase.firestore().collection("testimonials").doc();
                        const docId = docRef.id;

                        docRef.set({
                            uid: docId,
                            url: url,
                            name,
                            designation,
                            review
                        }).then(function () {
                            alert("Testimonial added");
                            window.location.reload();
                        });
                    });
                });
            } catch (err) {
                console.log(err);
            }
            setImage("");
        } else {
            alert("Please fill out all fields !");
        }
    };


    return (<div>
        <Check/> {
        loading == true ? (<Loading/>) : (<div>

            <hr/>
            <div className="container obn">
                <center>
                    <div className="tbn">
                        <h2>Testimonials</h2>

                        <div className="container obn">
                            <center>
                                <div className="tbn">
                                  
                                    <input type="file"
                                        onChange={
                                            (e) => {
                                                setImage(e.target.files[0]);
                                            }
                                        }
                                        className="btn-light btn w-100"/>

                                    <input type={"text"}
                                        onChange={
                                            (e) => setName(e.target.value)
                                        }
                                        placeholder="enter the name "
                                        className="mt-2 w-100"/>

                                    <input type={"text"}
                                        onChange={
                                            (e) => setDesignation(e.target.value)
                                        }
                                        placeholder="enter the name "
                                        className="mt-2 w-100"/>

                                    <textarea onChange={
                                            (e) => setReview(e.target.value)
                                        }
                                        placeholder="write review... "
                                        className="mt-2 w-100"/>


                                    <button className="btn btn-success w-100 mb-3"
                                        onClick={
                                            () => upload("testimonials")
                                    }>
                                        Upload
                                    </button>
                                </div>
                            </center>
                        </div>
                        <Row> {
                            items.map((p) => (<Col xs={12}
                                md={4}
                                lg={4}>
                                <div className="card-blog2">
                                    <img src={
                                            p.url
                                        }
                                        width="100%"
                                        className="b-img"/>
                                    <h5> {
                                        p.name
                                    }</h5>
                                    <i>{p.designation}</i>
                                    <p>{p.review}</p>

                                    <Button className="btn btn-danger mt-1"
                                        onClick={
                                            () => removeProduct(p.uid, p.url, "testimonials")
                                    }>
                                        X Remove
                                    </Button>
                                </div>
                            </Col>))
                        } </Row>
                    </div>
                    <hr/>
                </center>
            </div>
        </div>)
    } </div>);
};
export default AddTestimonials;
