import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {ReactComponent as Taxi} from "../../assets/taxi.svg";
import {ReactComponent as BookNow} from "../../assets/Book now.svg";
import {ReactComponent as Resort} from "../../assets/resort.svg";
import "./services.css";
import Carousel from 'react-bootstrap/Carousel';

const Services = () => {
    return (
        <div id='services'>
            <h1 className='mt-5 mb-2 text-center'>Our Services</h1>
            <Container className=''>

                <Row>

                    <Col lg={6}>
                        <div className='caption'>

                            <Row>
                                <Col lg={6}>
                                    <center>
                                        <Taxi className='vector'/>
                                    </center>
                                </Col>
                                <Col lg={6}
                                    className='p-5'>
                                    <center>
                                        <h3>
                                            <b>TAXI SERVICES</b>
                                        </h3>
                                        <a className='book-now btn' href='#contact'>
                                            <BookNow className='w-100'/>
                                        </a>
                                        <p>We provide taxi services  with experienced drivers
                                        </p>
                                    </center>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className='caption'>
                            <Row>
                                <Col lg={6}>
                                    <center>
                                        <Resort className='vector'/>
                                    </center>
                                </Col>
                                <Col lg={6}
                                    className='p-5'>
                                    <center>
                                        <h3>
                                            <b>RESORT SERVICES</b>
                                        </h3>
                                        <a className='book-now btn' href='#contact'>
                                            <BookNow className='w-100'/>
                                        </a>
                                        <p>We do resort bookings at a discount price
                                        </p>
                                    </center>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                    <Col lg={2}></Col>
                </Row>

            </Container>
        </div>
    )
}

export default Services
