import React from "react";
import {
    Navbar,
    Nav,
    Container,
    Row,
    Col
} from "react-bootstrap";

import './nav.css';
import {ReactComponent as FB} from "../../assets/icons/facebook-round.svg";
import {ReactComponent as TW} from "../../assets/icons/twitter-round.svg";
import {ReactComponent as YT} from "../../assets/icons/linkedin-round.svg";
import {ReactComponent as IG} from "../../assets/icons/insta.svg";
import {ReactComponent as Logo} from "../../assets/logo.svg";

const NavBar = () => {
    return (
        <Navbar variant="dark" expand="lg" sticky="top">
            <Container fluid>
                <Navbar.Brand href="#">
                    <Logo/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll"/>
                <Navbar.Collapse id="navbarScroll" className="justify-content-end regular">
                    <Nav>
                        <Nav.Link href="/#home">Home</Nav.Link>
                        <Nav.Link href="/#about">About</Nav.Link>
                        <Nav.Link href="/#services">Services</Nav.Link>
                        <Nav.Link href="/#packages">Packages</Nav.Link>
                        <Nav.Link href="/#contact">Contact</Nav.Link>
                        <Row>
                            <Col>
                                <Nav.Link href="https://www.facebook.com/profile.php?id=100094626992896&mibextid=ZbWKwL"><FB/></Nav.Link>
                            </Col>
                            <Col>
                                <Nav.Link href="https://instagram.com/e2m_enjoy_every_moment?igshid=NGExMmI2YTkyZg=="><IG/></Nav.Link>
                            </Col>
                            <Col>
                                <Nav.Link href="https://twitter.com/E2m_2000?t=E94ZBiXElJgLce9EOIpbGA&s=09"><TW/></Nav.Link>
                            </Col>
                            <Col>
                                <Nav.Link href="https://youtube.com/@E2Menjoyeverymoment"><YT/></Nav.Link>
                            </Col>
                        </Row>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
