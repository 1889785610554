import React from 'react'
import NavBar from '../Nav'
import {Col, Container, Row} from 'react-bootstrap'
import Footer from '../Footer'

import Img1 from "../../assets/nature/wayanad-4769648.jpg";
import Img2 from "../../assets/nature/munnar.webp";
import Img3 from "../../assets/nature/Alappuzha.webp";
import Img4 from "../../assets/nature/kovalam.webp";
import Img5 from "../../assets/nature/athirappally.webp";

import './mystic.css';
import { Fade } from 'react-reveal';

const MysticBeauty = () => {

    const places = [
        {
            img: Img1,
            title: "Wayanad",
            desc: "Travel to the top through Thamarassery churam by seeing the chain tree of the great karinthandan , experience a great forest ride by exploring caves , waterfalls & viewpoints."

        },
        {
            img: Img2,
            title: "Munnar - Thekkady",
            desc: "Go to tea plantations , their factories , birth place of different spices , elephant rides, jeep safari and traditional arts of kerala here."
        },
        {
            img: Img3,
            title: "Alapuzha ",
            desc: "Experience the world famous backwaters in our luxurious house boats and taste the traditional meals of kerala."
        },
        {
            img: Img4,
            title: "Varkala - Kovalam",
            desc: "Come and explore the beautiful beaches in kerala with vibrant cafes and adventurous water activities."
        }, {
            img: Img5,
            title: "Kochi & Athirappilly",
            desc: "You can find the beautiful chinese fishing nets , Portuguese influenced buildings and naval museums, also shop at one of the biggest malls in india & travel to the famous athirappilly water falls ."
        }
    ]
    return (
        <div id='mystic-beauty'>
            <NavBar/>
            <Container> {
                places.map((p) => (
                    <Fade up>
                        <Row className='place-card'>
                            <Col lg={4}><img src={
                                    p.img
                                }/></Col>
                            <Col>
                                <h3 className='mt-2'>{
                                    p.title
                                }</h3><hr/>
                                <p>{
                                    p.desc
                                }</p>
                            </Col>
                        </Row>
                    </Fade>
                ))
            } </Container>
            <Footer/>
        </div>
    )
}

export default MysticBeauty
