import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Containers/Home";
import MysticBeauty from "./Components/MysticBeauty";
import ScrollToTop from "./Components/ScrollToTop";
import {Whatsapp} from "./Components/Whatsapp";
import Login from "./Admin/Login";
import AddTestimonials from './Admin/AddClients'

const Entrypoint = () => {
    return (<Router>
        <ScrollToTop/>
        <Whatsapp/>
        <Routes>

            <Route exact path="/"
                element={<Home/>}/>
            <Route exact path="/Mystic-beauty"
                element={<MysticBeauty/>}/>
            <Route exact path="/Admin_login"
                element={<Login/>}/>
            <Route exact path="/Add_clients"
                element={<AddTestimonials/>}/>

        </Routes>
    </Router>);
};
export default Entrypoint;
