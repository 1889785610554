import React, {useState} from 'react'
import './packages.css';
import {Col, Container, Row} from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img1 from "../../assets/nature/munnar.webp";
import Img2 from "../../assets/nature/Alappuzha.webp";
import Img3 from "../../assets/nature/kovalam.webp";
import Img4 from "../../assets/nature/athirappally.webp";
import Slider from 'react-slick';
import {BrowserView, MobileOnlyView} from 'react-device-detect';
import Carousel from 'react-bootstrap/Carousel';
import {ReactComponent as GetQuote} from "../../assets/Get quote.svg";
import {Fade} from 'react-reveal';

const Packages = () => {
    const [read2, setRead2] = useState(0);
    const [read3, setRead3] = useState(0)


    const items = [
        {
            name: "Munnar",
            img: Img1
        }, {
            name: "Alappuzha",
            img: Img2

        }, {
            name: "Kovalam",
            img: Img3

        }, {
            name: "Athirappally",
            img: Img4

        }
    ]
    return (
        <div id='packages'>

            <h1 className='text-center bg-transp'>Our Packages</h1>
            <Carousel controls={false}>
                <Carousel.Item>
                    <Row className='row1 '>
                        <Col>
                            <BrowserView>
                                <Fade left>
                                    <div className='p-details'>
                                        <h3 className='white-color'>Mystic beauty of Kerala</h3>
                                        <p className='white-color'>Travel to the most unique places in Kerala
                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                        and discover why it's called the gods own country</p>

                                        <Row>

                                            <Col lg={3}>
                                                <a href='#contact' className='btn2'>Get Quote</a>
                                            </Col>
                                            <Col lg={3}>
                                                <a class="arrow-button" href='/mystic-beauty'>See more<span class="arrow"></span>
                                                </a>
                                            </Col>
                                            <Col lg={6}></Col>
                                        </Row>

                                    </div>
                                </Fade>
                            </BrowserView>
                            <MobileOnlyView>
                                <Fade left>
                                    <div className='p-details2'>
                                        <h3 className='white-color'>Mystic beauty of Kerala</h3>
                                        <p className='white-color'>Travel to the most unique places in Kerala
                                                                                                                                                                               
                                                                                                                                                                                and discover why it's called the gods own country</p>
                                        <Row>

                                            <Col xs={4}>
                                                <a href='#contact' className='btn2'>Get Quote</a>
                                            </Col>
                                            <Col xs={5}>
                                                <a class="arrow-button" href='/mystic-beauty'>See more<span class="arrow"></span>
                                                </a>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </div>
                                </Fade>
                            </MobileOnlyView>
                        </Col>
                        <Col lg={4}>
                            <BrowserView>
                                <Slider dots={true}


                                    slidesToShow={1}
                                    autoplay={true}
                                    autoplaySpeed={1500}>
                                    {
                                    items.map((p) => (

                                        <div className="card-package">
                                            <center>
                                                <img src={
                                                    p.img
                                                }/>
                                                <br/>
                                                <span>{
                                                    p.name
                                                }</span>
                                            </center>
                                        </div>

                                    ))
                                } </Slider>
                            </BrowserView>
                            <MobileOnlyView>
                                <Slider dots={true}


                                    slidesToShow={1}
                                    autoplay={true}
                                    autoplaySpeed={1500}>
                                    {
                                    items.map((p) => (

                                        <div className="card-package2">
                                            <center>
                                                <img src={
                                                    p.img
                                                }/>

                                                <span>{
                                                    p.name
                                                }</span>
                                            </center>
                                        </div>

                                    ))
                                } </Slider>
                            </MobileOnlyView>
                        </Col>

                    </Row>
                </Carousel.Item>
                <Carousel.Item>

                    <Row className='row2'>
                        <Col></Col>
                        <Col lg={8}>
                            <center>
                                <Fade down>
                                    <div className='p-details bg-dark2'>
                                        <h3 className='white-color'>Medical tourism
                                        </h3>
                                        <p className='white-color'>Rejuvenate your body with the ancient ayurvedic magic & other treatments with professional doctors at affordable prices .

                                        </p>
                                        {
                                        read2 == 1 ? <Fade down>
                                            <p className='white-color'>We have top hospitals and ayurvedic centres who will treat you with care.
                                            </p>
                                        </Fade> : null
                                    }
                                        <BrowserView>
                                            <Row>
                                                <Col lg={3}></Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    <a href='#contact' className='btn2'>Get Quote</a>
                                                </Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    {
                                                    read2 == 0 ? <button onClick={
                                                            () => setRead2(1)
                                                        }
                                                        class="arrow-button">Read More<span class="arrowdown"></span>
                                                    </button> : <button onClick={
                                                            () => setRead2(0)
                                                        }
                                                        class="arrow-button">Read Less<span class="arrowup"></span>
                                                    </button>
                                                } </Col>
                                                <Col lg={3}></Col>
                                            </Row>
                                        </BrowserView>
                                        <MobileOnlyView>
                                            <Row>
                                                <Col lg={3}></Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    <a href='#contact' className='btn2'>
                                                        <center>Get Quote</center>
                                                    </a>
                                                </Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    {
                                                    read2 == 0 ? <button onClick={
                                                            () => setRead2(1)
                                                        }
                                                        class="arrow-button">Read More<span class="arrowdown"></span>
                                                    </button> : <button onClick={
                                                            () => setRead2(0)
                                                        }
                                                        class="arrow-button">Read Less<span class="arrowup"></span>
                                                    </button>
                                                } </Col>
                                                <Col lg={3}></Col>
                                            </Row>
                                        </MobileOnlyView>
                                    </div>
                                </Fade>
                            </center>
                        </Col>
                        <Col></Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row className='row-3'>
                        <Col></Col>
                        <Col lg={8}>
                            <center>
                                <Fade down>
                                    <div className='p-details bg-dark2'>
                                        <h3 className='white-color'>Adventure tourism
                                        </h3>
                                        <p className='white-color'>Travel to places that are not found on your map & do the wildest things you can ever imagine . Learn survival skills & awaken your inner strengths .
                                        </p>
                                        {
                                        read3 == 1 ? <Fade down>
                                            <p className='white-color'>Be a part of adventure camps ,stay in tent , learn how to build a raft , trek to the biggest peaks in kerala and also participate in different activities and games .


                                            </p>
                                        </Fade> : null
                                    }
                                        <BrowserView>
                                            <Row>
                                                <Col lg={3}></Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    <a href='#contact' className='btn2'>Get Quote</a>
                                                </Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    {
                                                    read3 == 0 ? <button onClick={
                                                            () => setRead3(1)
                                                        }
                                                        class="arrow-button">Read More<span class="arrowdown"></span>
                                                    </button> : <button onClick={
                                                            () => setRead3(0)
                                                        }
                                                        class="arrow-button">Read Less<span class="arrowup"></span>
                                                    </button>
                                                }</Col>
                                                <Col lg={3}></Col>
                                            </Row>
                                        </BrowserView>
                                        <MobileOnlyView>
                                            <Row>

                                                <Col lg={3}
                                                    xs={6}>
                                                    <a href='#contact' className='btn2'>Get Quote</a>
                                                </Col>
                                                <Col lg={3}
                                                    xs={6}>
                                                    {
                                                    read3 == 0 ? <button onClick={
                                                            () => setRead3(1)
                                                        }
                                                        class="arrow-button">Read More<span class="arrowdown"></span>
                                                    </button> : <button onClick={
                                                            () => setRead3(0)
                                                        }
                                                        class="arrow-button">Read Less<span class="arrowup"></span>
                                                    </button>
                                                }</Col>

                                            </Row>
                                        </MobileOnlyView>

                                    </div>
                                </Fade>
                            </center>
                        </Col>
                        <Col></Col>
                    </Row>
                </Carousel.Item>
            </Carousel>

        </div>
    )
}

export default Packages
